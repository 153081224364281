@use "@angular/material" as mat;

@use "./m3-theme";

// // global app font size
@use "./font-size";

// // themes - our custom or/and out of the box themes
@use "themes";

@use "slate-angular/styles/index.scss";

@tailwind base;

@layer base {
  h1 {
    @apply text-3xl font-bold text-gray-900;
  }

  h2 {
    @apply text-2xl font-semibold text-gray-900;
  }

  h3 {
    @apply text-xl font-semibold text-gray-900;
  }

  h4 {
    @apply text-lg font-semibold text-gray-900;
  }

  h5 {
    @apply text-base font-semibold text-gray-900;
  }
}

@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  font-family: "Lexend Deca" !important;
  @apply text-gray-700;
}

* {
  font-family: "Lexend Deca" !important;
  letter-spacing: normal !important;
}

.prose-lg h3 {
  font-size: 1.33333333em !important;
}

body {
  margin: 0;
  position: relative !important;
}

/* Fixes CKEditor styles */

.cke_dialog {
  [type="checkbox"],
  [type="radio"] {
    width: 1rem !important;
    height: 1rem !important;
    color: #f149ab !important;
    border: 1px solid #6b7280 !important;

    &::before,
    &::after {
      border: 1px solid #6b7280 !important;
      box-sizing: border-box !important;
    }
  }
}

select.cke_dialog_ui_input_select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e") !important;
  background-position: right 0.5rem center !important;
  background-repeat: no-repeat !important;
  background-size: 1.5em 1.5em !important;
  padding-right: 2.5rem !important;
  print-color-adjust: exact;
  height: 40px !important;
}

a.cke_dialog_ui_button_ok {
  color: #fff !important;
  background-color: #f149ab !important;
}

.vg-tooltip img {
  max-width: 250px !important;
  max-height: 350px !important;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
  @apply border-b border-b-gray-200;
}

/* Material theming */
@include mat.core();
html {
  // Include all theme styles for the components.
  @include mat.all-component-themes(m3-theme.$light-theme);

  // Override variables here to customize single things in components
  --mdc-filled-text-field-container-color: #fff;
  --mat-select-panel-background-color: #fff;
  --mat-expansion-container-background-color: #fff;
  --mat-table-background-color: #fff;
  --mat-paginator-container-background-color: #fff;
  --mat-option-selected-state-layer-color: #f5faff;
  --mat-datepicker-calendar-container-background-color: #fff;
  --mdc-switch-unselected-track-color: #fff;

  .mat-expansion-panel-body {
    padding-top: 1rem !important;
  }
}

label {
  margin: 0;
}

// Make sure ng-select dropdowns stay exactly one z-index above modals
.ng-dropdown-panel {
  z-index: 1060 !important;
}

// basic richtext styles
.slate-editable-container {
  [slate-underlined][slate-strike] {
    text-decoration: underline line-through;
  }
  [slate-strike] {
    text-decoration: line-through;
  }
  [slate-underlined] {
    text-decoration: underline;
  }
  [slate-italic] {
    font-style: italic;
  }
  [slate-bold] {
    font-weight: bold;
  }
  [slate-code-line] {
    margin: 0 4px;
    padding: 2px 3px;
    border: 1px solid rgba($color: #000000, $alpha: 0.08);
    border-radius: 2px;
    background-color: rgba($color: #000000, $alpha: 0.06);
  }

  blockquote {
    margin: 0;
    margin-left: 0;
    margin-right: 0;
    color: #888;
    padding-left: 10px !important;
    border-left: 4px solid #eee;
  }

  h1,
  h2,
  h3 {
    margin: 0px;
  }

  & > [data-slate-node="element"],
  & > slate-block-card {
    margin-bottom: 12px;
  }
}
