@use '../components/block-card/block-card.component.scss';
.slate-editable-container {
    display: block;
    outline: none;
    padding: 32px;
    white-space: break-spaces;
    & [contenteditable='true'] {
        outline: none;
    }
    & [data-slate-placeholder] {
        position: absolute;
        pointer-events: none;
        width: 100%;
        max-width: 100%;
        display: block;
        opacity: 0.333;
        user-select: none;
        text-decoration: none;
        top: 0;
    }
    & [data-slate-leaf='true'] {
        &.leaf-with-placeholder {
            position: relative;
            display: inline-block;
            width: 100%;
        }
    }
    &.firefox {
        // Compatible for firefox, there are two problems with using inline-block
        // Issue-1: paragraph height becomes taller
        // Issue-2: blocks focus movement on key down
        .leaf-with-placeholder {
            display: inline-flex !important;
        }
    }
}

.slate-spacer {
    height: 0;
    color: transparent;
    outline: none;
    position: absolute;
}
